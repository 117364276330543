var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-md-6 text-center text-md-left"},[_c('h2',[_vm._v("Your portfolio - "+_vm._s(_vm.totalUSD))])]),_c('div',{staticClass:"col-md-6 text-center text-md-right"},[_c('div',[_vm._m(0),_c('b-button',{staticClass:"ml-3",attrs:{"variant":"primary","disabled":!_vm.currentWallet ||
              Object.keys(_vm.currentWallet).length === 0 ||
              _vm.currentWallet.constructor === Object ||
              _vm.loading},on:{"click":_vm.reloadTxs}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Reload all data ")],1)],1)])]),_c('div',{staticClass:"row"},[(
        !_vm.currentWallet ||
          Object.keys(_vm.currentWallet).length === 0 ||
          _vm.currentWallet.constructor === Object
      )?_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"text-center"},[_vm._v(" You need to connect your wallet first. ")])]):_vm._l((_vm.tokens),function(item,index){return _c('div',{key:index,staticClass:"col-xl-4"},[_c('TokenBlock',{attrs:{"tokenData":item},on:{"hide":_vm.hide}})],1)})],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[(_vm.hidden.length)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.showHidden}},[_vm._v(" Show hidden tokens ")]):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"w-100"},[_vm._v("Values will be refreshed after "),_c('strong',[_vm._v("30s")])])}]

export { render, staticRenderFns }