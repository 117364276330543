<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row my-3">
      <div class="col-md-6 text-center text-md-left">
        <h2>Your portfolio - {{ totalUSD }}</h2>
      </div>
      <div class="col-md-6 text-center text-md-right">
        <div>
          <span class="w-100"
            >Values will be refreshed after <strong>30s</strong></span
          >
          <b-button
            class="ml-3"
            variant="primary"
            @click="reloadTxs"
            :disabled="
              !currentWallet ||
                Object.keys(currentWallet).length === 0 ||
                currentWallet.constructor === Object ||
                loading
            "
          >
            <b-spinner small v-if="loading"></b-spinner>
            Reload all data
          </b-button>
        </div>
      </div>
    </div>
    <!--begin::Row-->
    <div class="row">
      <div
        class="col-12"
        v-if="
          !currentWallet ||
            Object.keys(currentWallet).length === 0 ||
            currentWallet.constructor === Object
        "
      >
        <h3 class="text-center">
          You need to connect your wallet first.
        </h3>
      </div>
      <div class="col-xl-4" v-for="(item, index) in tokens" :key="index" v-else>
        <TokenBlock :tokenData="item" @hide="hide"></TokenBlock>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <v-btn color="primary" @click="showHidden" v-if="hidden.length">
          Show hidden tokens
        </v-btn>
      </div>
    </div>
    <!--end::Row-->
    <!--end::Dashboard-->
  </div>
</template>

<script>
import TokenBlock from "@/view/content/widgets/list/TokenBlock.vue";
import Web3service from "@/core/services/web3.service";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "dashboard",
  components: {
    TokenBlock,
  },
  data: () => ({
    headers: [
      {
        text: "Type",
        align: "start",
        sortable: false,
        value: "typeText",
      },
      {
        text: "Block",
        align: "start",
        sortable: false,
        value: "block",
      },
      { text: "Transaction amount", value: "transferAmount", align: "end" },
      { text: "Currency", value: "currency" },
      { text: "Date", value: "dateFormatted", align: "end" },
    ],
    infoIcon: null,
    copyIcon: null,
    donate: "0x031e8680EC8C279E7D9Ec26FEe9F246C024013d1",
    tokens: null,

    hidden: [],
    loading: false,
    copied: false,
    polling: null,
    txs: [],
    resetTxs: false,
    search: "",
    allTxs: [],
    valid: false,
    rules: {
      required: value => !!value || "Required.",
    },
  }),
  beforeDestroy() {
    clearInterval(this.polling);
  },
  watch: {
    currentWallet: function(newWallet) {
      if (!newWallet) {
        this.txs = [];
        this.tokens = [];
        return;
      }
      this.reloadTxs();
    },
  },
  computed: {
    ...mapGetters(["currentWallet"]),
    totalUSD() {
      if (this.tokens) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(
          this.tokens.reduce(
            (total, obj) =>
              (parseFloat(obj.balanceUsdNum) + parseFloat(total)).toFixed(),
            0
          )
        );
      } else {
        return 0;
      }
    },
  },
  mounted() {
    console.log("mounted");
    if (this.currentWallet) {
      clearInterval(this.polling);
      if (localStorage.hidden) {
        this.hidden = JSON.parse(localStorage.hidden);
      }
      if (!localStorage.txs) {
        this.getAllTokens();
      } else if (localStorage.tokens) {
        this.txs = JSON.parse(localStorage.txsTable);
        this.tokens = JSON.parse(localStorage.tokens);
        this.getTokensData();
        this.polling = setInterval(() => {
          this.getTokensData();
        }, 30000);
      } else {
        this.getAllTokens();
      }
    }
  },
  methods: {
    showHidden() {
      this.tokens.push(...this.hidden);
      this.tokens = this.tokens.sort(function(a, b) {
        return b.balanceUsdNum - a.balanceUsdNum;
      });
      this.hidden = [];
      localStorage.removeItem("hidden");
      localStorage.setItem("tokens", JSON.stringify(this.tokens));

      clearInterval(this.polling);
      this.polling = setInterval(() => {
        this.getTokensData();
      }, 30000);
    },
    hide(item) {
      this.hidden.push(item);
      localStorage.hidden = JSON.stringify(this.hidden);
      const index = this.tokens.indexOf(item);
      if (index > -1) {
        this.tokens.splice(index, 1);
      }
      localStorage.tokens = JSON.stringify(this.tokens);
    },
    submitForm() {
      clearInterval(this.polling);
      this.getAllTokens();
    },
    onCopy: function() {
      this.copied = true;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    row_classes(item) {
      return item.type;
    },
    reloadTxs() {
      clearInterval(this.polling);
      this.resetTxs = true;
      localStorage.removeItem("txs");
      localStorage.removeItem("txsTable");
      this.getAllTokens();
    },
    async getTokensData() {
      let vm = this;
      this.loading = true;
      let bnbToUsd = await Web3service.getBnbToUsd();
      await Promise.all(
        this.tokens.map(async token => {
          let balance = await Web3service.getTokenBalanceWeb3(
            token.address,
            vm.currentWallet,
            token.decimals
          );

          let newData = await this.getTokenData(
            token.address,
            token.name,
            token.decimals,
            bnbToUsd,
            balance,
            token.earnedNum,
            token.sold
          );

          Object.assign(token, newData);
        })
      ).then(() => {
        this.loading = false;
      });
    },
    async getAllTokens() {
      if (!this.currentWallet) {
        localStorage.currentWallet = "";
        clearInterval(this.polling);
        return;
      }
      console.log(this.resetTxs);
      let valid = await Web3service.checkAddress(this.currentWallet);
      if (valid) {
        if (this.tokens && !this.resetTxs) {
          console.log("saved");
          this.getTokensData();
          clearInterval(this.polling);
          this.polling = setInterval(() => {
            this.getTokensData();
          }, 30000);
          return;
        }

        localStorage.currentWallet = this.currentWallet;
        this.resetTxs = false;
        this.loading = true;
        let vm = this;

        let txs = await this.getTxs();
        if (txs == null) {
          clearInterval(this.polling);
          this.loading = false;
          return;
        }
        let tokens = [];
        let txsData = [];

        let bnbToUsd = await Web3service.getBnbToUsd();
        console.log("bnbToUsd", bnbToUsd);
        if (txs) {
          await Promise.all(
            txs.map(async currency => {
              let token = currency.currency.address;
              let symbol = currency.currency.symbol;
              let decimals = currency.currency.decimals;
             /*  if (currency.history) {
                await Promise.all(
                  currency.history.map(async txItem => {
                    let txRow = {
                      type: txItem.transferAmount < 0 ? "sell" : "buy",
                      typeText: txItem.transferAmount < 0 ? "Sell" : "Buy",
                      currency: symbol,
                      value: txItem.value,
                      transferAmount: txItem.transferAmount,
                      timestamp: txItem.timestamp,
                      dateFormatted: moment(txItem.timestamp).format("LLL"),
                      block: txItem.block,
                    };
                    txsData.push(txRow);
                  })
                );
              } */

              if (token == "-" || symbol == "USDT" || currency.value == 0) {
                return;
              }

              let balance = await Web3service.getTokenBalanceWeb3(
                token,
                vm.currentWallet,
                decimals
              );
              if (parseFloat(balance) == 0) {
                return;
              }

              let tokenData = await this.getTokenData(
                token,
                currency.currency.name,
                decimals,
                bnbToUsd,
                balance
              );
              if (tokenData) {
                tokens.push(tokenData);
              }
            })
          ).then(() => {
            let txsTable = txsData.sort(function(a, b) {
              return new Date(b.timestamp) - new Date(a.timestamp);
            });
            vm.txs = txsTable;
            localStorage.txsTable = JSON.stringify(txsTable);
            vm.hidden = [];
            vm.tokens = tokens.sort(function(a, b) {
              return b.balanceUsdNum - a.balanceUsdNum;
            });
            localStorage.tokens = JSON.stringify(tokens);
            clearInterval(this.polling);
            this.polling = setInterval(() => {
              this.getTokensData();
            }, 30000);
            vm.loading = false;
          });
        }
      }
    },
    async getTokenData(
      token,
      tokenName,
      decimals,
      bnbToUsd,
      balance,
      earned,
      sold
    ) {
      let vm = this;
      let tokenToBnbResp;
      let tokenToBnbResp2;
      let pair = await Web3service.getTokenLpAddressWeb3(token, false);
      let pair2 = await Web3service.getTokenLpAddressWeb3(token, true);
      if (
        pair != null &&
        pair != "0x0000000000000000000000000000000000000000"
      ) {
        tokenToBnbResp = await Web3service.getTokenToBnb(pair, balance);
      }
      if (
        pair2 != null &&
        pair2 != "0x0000000000000000000000000000000000000000"
      ) {
        tokenToBnbResp2 = await Web3service.getTokenToBnb(pair2, balance);
      }

      let tokenToBnb;
      let exchangeVal;

      if (tokenToBnbResp && tokenToBnbResp2) {
        if (tokenToBnbResp.exchangeVal > tokenToBnbResp2.exchangeVal) {
          tokenToBnb = tokenToBnbResp.price;
          exchangeVal = tokenToBnbResp.exchangeVal;
        } else {
          tokenToBnb = tokenToBnbResp2.price;
          exchangeVal = tokenToBnbResp2.exchangeVal;
        }
      } else if (!tokenToBnbResp && tokenToBnbResp2) {
        tokenToBnb = tokenToBnbResp2.price;
        exchangeVal = tokenToBnbResp2.exchangeVal;
      } else if (!tokenToBnbResp2 && tokenToBnbResp) {
        tokenToBnb = tokenToBnbResp.price;
        exchangeVal = tokenToBnbResp.exchangeVal;
      }

      let supply = await Web3service.getTokenTotalSupply(token, decimals);

      if (!tokenToBnb) {
        return;
      }

      let tokenToUsd = tokenToBnb * bnbToUsd;
      console.log("tokenToUsd", tokenToUsd);

      let balanceUsd = (balance * tokenToUsd).toFixed(2);

      let exchangeValUsdt = exchangeVal * 0.98 * bnbToUsd;

      if (exchangeValUsdt > balanceUsd) {
        exchangeValUsdt = balanceUsd;
      }

      let exchangeValUsdtFormated = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(parseFloat(exchangeValUsdt).toFixed(2));

      let burnt = await Web3service.getBurnt(token, decimals);
      let cap =
        (parseFloat(supply) - parseFloat(burnt)) *
        parseFloat(tokenToBnb) *
        parseFloat(bnbToUsd);

      let earnedUsd = 0;
      if (earned) {
        earnedUsd = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format((earned * tokenToUsd).toFixed(2));
      }
      let address = await Web3service.toChecksumAddress(token);
      return {
        active: true,
        decimals: decimals,
        balance: vm.numberWithCommas(balance),
        name: tokenName,
        earned: vm.numberWithCommas(parseFloat(earned).toFixed(3)),
        earnedNum: earned,
        earnedUsd: earnedUsd,
        currentBal: balance,
        sold: sold,
        exchangeValUsdt: exchangeValUsdtFormated,
        balanceUsd: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(balanceUsd),
        balanceUsdNum: balanceUsd,
        current: (tokenToBnb * bnbToUsd * 1000000000).toFixed(6),
        cap: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(cap),
        address: address,
      };
    },
    async getTxs() {
      if (localStorage.txs) {
        return JSON.parse(localStorage.txs);
      } else {
        return ApiService.post("https://api.mywallettracker.com/api/getTxs", {
          wallet: this.currentWallet,
          resetTxs: this.resetTxs,
        })
          .then(response => {
            localStorage.txs = JSON.stringify(response.data);
            return response.data;
          })
          .catch(err => {
            console.log(err);
            return null;
          });
      }
    },
  },
};
</script>
