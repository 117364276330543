<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1">
        <!--begin::Info-->
        <div class="d-flex align-items-center mb-6">
          <div class="symbol symbol-50 mr-5">
            <span class="symbol-label bg-light-light">
              <img
                :src="
                  'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/' +
                    tokenData.address +
                    '/logo.png'
                "
                @error="imageUrlAlt"
                class="h-100 align-self-center"
                alt=""
              />
            </span>
          </div>
          <!--begin::Link-->
          <span class="text-dark font-weight-bolder font-size-h4 w-100">{{
            tokenData.name
          }}</span>
          <a
            @click="hide(tokenData)"
            class="symbol symbol-45 text-hover-primary"
          >
            <div class="symbol-label">
              <i class="flaticon-eye icon-lg text-info"></i>
            </div>
          </a>
          <!--end::Link-->
        </div>
        <!--end::Info-->

        <!--begin::Desc-->
        <div class="d-flex justify-content-between">
          <p class="text-dark-50 font-weight-normal font-size-lg">
            <strong>Balance:</strong>
          </p>
          <p class="text-dark-50 font-weight-normal font-size-lg">
            <span>{{ tokenData.balance }}</span>
          </p>
        </div>
        <div class="d-flex justify-content-between">
          <p class="text-dark-50 font-weight-normal font-size-lg">
            <strong>Balance USDT:</strong>
          </p>
          <p class="text-dark-50 font-weight-normal font-size-lg">
            <span>{{ tokenData.balanceUsd }}</span>
          </p>
        </div>
        <div class="d-flex justify-content-between">
          <p class="text-dark-50 font-weight-normal font-size-lg">
            <strong>Reduced with price impact:</strong>
          </p>
          <p class="text-dark-50 font-weight-normal font-size-lg">
            <span>{{ tokenData.exchangeValUsdt }}</span>
          </p>
        </div>
        <div class="d-flex justify-content-between">
          <p class="text-dark-50 font-weight-normal font-size-lg">
            <strong>Market cap:</strong>
          </p>
          <p class="text-dark-50 font-weight-normal font-size-lg">
            <span>{{ tokenData.cap }}</span>
          </p>
        </div>

        <!--end::Desc-->
      </div>
      <!--begin::Team-->
      <div class="d-flex align-items-center">
        <!--begin::Pic-->

        <a
          :href="'https://poocoin.app/tokens/' + tokenData.address"
          target="_blank"
          class="symbol symbol-45 symbol-light mr-3 text-hover-primary"
        >
          <div class="symbol-label">
            <i class="flaticon2-line-chart icon-lg text-info"></i>
          </div>
        </a>
        <!--end::Pic-->
        <!--begin::Pic-->
        <a
          :href="'http://bscscan.com/token/' + tokenData.address"
          class="symbol symbol-45 symbol-light mr-3 text-hover-primary"
          target="_blank"
        >
          <div class="symbol-label">
            <i class="flaticon2-paper icon-lg text-success"></i>
          </div>
        </a>

        <!--end::Pic-->
      </div>
      <!--end::Team-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "Widget11",
  props: {
    tokenData: null,
  },
  created() {
    console.log(this.tokenData);
  },
  methods: {
    hide(tokenData) {
      this.$emit("hide", tokenData);
    },
    imageUrlAlt(event) {
      event.target.src =
        process.env.BASE_URL + "media/svg/icons/General/Attachment1.svg";
    },
  },
};
</script>
